.contacts-container {
  padding-bottom: var(--contact-pb);
  padding-top: var(--contact-pt);
  display: flex;
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  gap: 90px;

}

.contacts-left {
  background-color: var(--yellow);
  width: 638px;
  height: 744px;
  position: relative;
  margin-left: 82px;
  font-size: var(--16px);
  background-image: url('../../assets/img/pedutes-group.webp');
  background-position: 24rem 29rem;
  /* background-repeat: no-repeat; */
  background-size: 105%;
}

.open-times {
  position: absolute;
  width: 214px;
  height: 201px;
  left: -60px;
  top: 83px;
  padding: 68px 28px;
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.open-times ul {
  padding: 0;
  margin: 0;
}

.open-times ul li {
  list-style: none;
  line-height: 30px;

  font-weight: var(--regular);
}

.location {
  position: absolute;
  padding: 68px 28px;
  background-color: white;
  bottom: 42px;
  right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.location img {
  width: 50px;
  height: 50px;
  margin-bottom: 38px;
  margin-right: auto;
}

.location-desc p:first-of-type {
  margin-bottom: 30px;
}

.location-desc span {
  font-weight: var(--semi-bold);
}

.contacts-paws {
  position: absolute;
  left: -2.8rem;
  bottom: 4.5rem;
  z-index: 0;
  transform: translateX(114px);
}

/* contact-right */

.contacts-right h2 {
  font-size: var(--28px);
  font-weight: var(--semi-bold);
  margin-bottom: 40px;
  margin-top: 83px;
}

.fb-icon {
  color: black;
  font-size: 40px;
  margin-top: 2px;
}

.phone-fb {
  display: flex;
  align-items: start;
  gap: 50px;
  margin-bottom: 20px;
}

.phones-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.email-phone a:not(.fb-icon) {
  font-size: var(--21px);
  font-weight: var(--regular);
  text-decoration: none;
  color: var(--black);
}

.phone-fb a {
  margin-bottom: 5px;
}

.email-btn-container a {
  font-size: var(--21px);
  font-weight: var(--regular);
  text-decoration: none;
  color: var(--black);
  /* margin-bottom: 35px; */
}

/* form */

.contacts-form {
  width: 493px;
}

.contacts-form form {
  font-family: 'Red Hat Display', sans-serif;
  display: flex;
  flex-direction: column;
  padding: 13px 25px 0px 25px;
  background-color: var(--grey);
  margin-bottom: 26px;
}

.contacts-form form label {
  font-size: var(--16px);
  padding-bottom: 7px;
}

.contacts-form form label:nth-child(3) {
  padding-top: 13px;
}

.contacts-form input,
textarea {
  padding: 6px;
  outline: none;
  border: none;
  resize: none;
}

.form-btn {
  font-size: var(--21px);
  border-radius: var(--border-radius);
  padding: 13px 31px 14px 31px;
  border: none;
  background-color: var(--green);
  color: white;
  z-index: 2;
}


.form-btn:hover {
  background-color: var(--hover-green);
}

.remodal {
  font-size: 12px;
  position: absolute;
  bottom: 0;
  padding-left: 5px;
  padding-bottom: 5px;
}

.email-btn-container {
  margin-bottom: 35px;
}

.email-btn-container button {
  display: none;
}

.error-message {
  color: rgb(235, 15, 15);
}

@media (max-width: 768px) {
  .contacts-container {
    flex-direction: column;
    gap: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .contacts-left {
    margin: 0 auto;
    height: 460px;
    width: calc(100vw - 40px);
  }

  .open-times {
    left: 15px;
    top: 0;
    padding: 20px;
  }

  .location {
    padding: 20px;
    bottom: 15px;
    right: 15px;
  }

  .location img {
    width: 33px;
    height: 28px;
    object-fit: contain;
    margin-bottom: 17px;
    margin-right: auto;
  }

  .location-desc p:first-of-type {
    margin-bottom: 10px;
  }

  .contacts-paws {
    position: relative;
  }

  .contacts-right {
    position: relative;
    flex-grow: 1;
  }

  .contacts-right h2 {
    font-size: var(--21px);
    margin: 0px 0px 15px 0px;
  }

  .form-socials {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;

  }

  .email-phone {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin-top: -50px;
  }

  .phone-fb {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .email {
    margin-bottom: 20px;
  }

  .email-phone p,
  .phone-fb p {
    font-size: var(--16px);
  }

  .fb-icon {
    width: 25px;
    height: 25px;
    object-fit: contain;
  }

  .contacts-form {
    width: calc(100vw - 40px);
    display: flex;
    flex-direction: column;
  }

  .contacts-form form {
    padding: 15px;
    margin-bottom: 20px;
    padding-bottom: 0;
  }

  .form-btn {
    font-size: var(--16px);
    margin-left: auto;
    padding: 8px 14px;
    border-radius: 10px;
  }

  .contacts-paws img {
    position: absolute;
    left: 0rem;
    top: 15rem;
  }

  .open-times {
    left: 20px;
    top: 19px;
    padding: 11px;
    padding-bottom: 15px;
  }

  .location {
    padding: 15px;
    bottom: 20px;
    left: 20px;
    width: max-content;
    z-index: 1;
    padding-right: 23px;
  }

  .remodal {
    display: none;
    bottom: -31.5rem;
    right: 0rem;
  }

  .email-btn-container button {
    display: block;
  }

  .email-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0px;
  }
}


@media screen and (min-width: 769px) and (max-width: 1024px) {
  .contacts {
    padding: 0px 85px;
  }

  .contacts-container {
    flex-direction: column;
  }

  .contacts-paws {
    left: -2.8rem;
    bottom: 6rem;
  }

  .contacts-left {
    max-width: 538px;
    margin-left: 75px;
  }

  .contacts-right {
    margin: 0 auto;
  }

  .email-hone {
    flex-basis: 30%;
  }

  .form-socials {
    display: flex;
    flex-direction: row-reverse;
    gap: 5rem;
  }

  .contacts-form {
    width: 325px;
  }

  .phone-fb {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    margin-bottom: 19px;
    flex-direction: column-reverse;
  }

}