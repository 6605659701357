:root {
  --yellow: #F8C81F;
  --green: #348A0C;
  --black: #000000;
  /* formos  */
  --grey: #C2C1C1;
  /* opacity 0.7 */
  --hover-grey: rgba(194, 193, 193, 0.7);
  --active-grey: #8E8E8E;

  /* p grey */

  --p-grey: #353535;

  /* button hover */

  --hover-green: #2f7a0c;

  --border-radius: 20px;

  /* tablet padding */
  --tablet-padding: 20px;

  /* popular items paddings */
  --pop-pt: 65px;
  --pop-pb: 94px;

  /* catalog paddings */
  --catalog-pt: 55px;
  --catalog-pb: 132px;

  /* contact paddings */
  --contact-pt: 90px;
  --contact-pb: 70px;

  --semi-bold: 500;
  --regular: 400;

  --16px: 16px;
  --21px: 21px;
  --28px: 28px;
  --37px: 37px;

  /* footeryje, pavadinimas */
  --remodal: 12px;
}