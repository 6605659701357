.catalog-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-bottom: var(--catalog-pb);
}

.catalog-title {
  padding-top: var(--catalog-pt);
  font-size: var(--28px);
  font-weight: var(--regular);
  padding-bottom: 6px;
}

.single-category-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 34px;
}

.single-category-item:hover {
  transition: 0.2s ease-in-out;
  background-color: var(--hover-grey);
  cursor: pointer;
}

.single-category-item p {
  font-size: var(--21px);
  padding: 0px 33px;
  padding-left: 0;
}

.single-category-item img {
  padding-left: 5px;
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.categories-left-side {
  padding: 30px 0px;
  background: var(--yellow);
  position: absolute;
  left: -1700px;
  padding-left: 1700px;
  display: flex;
  flex-direction: column;
  gap: 49px;
}

.catalog-right-side {
  display: flex;
}

.right-side-photo {
  display: flex;
  flex-direction: column;
  margin-right: 36px;
  position: relative
}

.right-side-photo img {
  width: 442px;
  height: 616px;
  object-fit: cover;
}

.left-side-desc {
  padding-left: 72px;
  margin-top: 227px;
}

.left-side-desc img {
  width: 380px;
}

.photo-credit {
  position: absolute;
  font-size: 6px;
  right: 0;
  bottom: 4px;
}

.photo-header {
  margin-top: 6px;
  text-align: center;
  font-weight: var(--regular);
  line-height: 1.6;
}

.photo-header span {
  font-weight: 700;
}

.close-icon {
  font-size: 45px;
}

.close-icon:hover {
  transition: 0.5s ease-in-out;
  transform: rotate(180deg);
}

.single-cat-product {
  cursor: pointer;
  width: 170px;
}

.single-cat-product p {
  color: var(--p-grey);
  text-align: left;
  font-size: var(--16px);
  font-weight: var(--regular);
}

.single-cat-product p:last-of-type {
  color: var(--black);
  font-weight: var(--semi-bold);
}

.single-cat-product img {
  width: 100%;
  height: 230px;
  object-fit: contain;
}

.catalog-slide {
  position: absolute;
  top: -50px;
  right: 0;
  height: 765px;
  width: 1000px;
  display: flex;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  overflow: hidden;

}

.products-with-pagination {
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
}

.page-products {
  display: grid;
  column-gap: 20px;
  row-gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 330px 330px;
  margin-bottom: 34px;
  user-select: none,
}

.products-with-pagination-logo {
  background-color: var(--yellow);
  width: 100%;
  height: 70px;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tree-products {
  margin-bottom: 20px;
  padding: 0 20px;
  min-height: 100vh;
  cursor: pointer;
}

.tree-products h4 {
  color: var(--p-grey);
  font-size: var(--16px);
  font-weight: var(--regular);
}

.single-product {
  max-width: 210px;
  height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.single-product p {
  color: var(--p-grey);
  font-weight: var(--regular);
}

.single-product p:last-of-type {
  color: var(--black);
  font-weight: var(--semi-bold);
}

.catalog-mobile-title {
  display: none;
}

.modal-container {
  display: grid;
  grid-template-columns: 170px auto auto auto;
  grid-template-rows: auto auto auto auto auto auto;
  column-gap: 10px;
  grid-template-areas:
    'img analysis vitPerKg feedingGuide'
    'fPart fPart fPart fPart'
    'sPart sPart sPart sPart'
    'tPart tPart tPart tPart'
    'keyBenefits keyBenefits keyBenefits keyBenefits'
    'ingredients ingredients ingredients ingredients'
  ;
}

.modal-container img {
  width: 50%;
}

.modal-container-desc {
  display: flex;
  flex-direction: column;
}

.product-modal-icon {
  font-size: 25px;
}

.product-modal-icon:hover {
  cursor: pointer;
  transition: 0.3s ease-in-out;
  transform: rotate(180deg);
}


@media(max-width: 768px) {
  .catalog-container {
    display: none;
  }

  .catalog-title {
    display: none;
  }

  .catalog-mobile-title {
    padding-top: 0;
    padding: 0 20px;
    font-size: var(--21px);
    font-weight: var(--regular);
  }

  .products-with-pagination {
    display: flex;
  }

  .modal-mobile-container {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0;
    grid-template-areas:
      'img'
      'analysis'
      'vitPerKg'
      'feedingGuide'
      'fPart'
      'sPart'
      'tPart'
      'keyBenefits'
      'ingredients'
    ;
    row-gap: 10px;

  }


  .modal-mobile-container>div {
    width: 100%
  }

}

@media screen and (min-width: 769px) and (max-width: 1024px) {

  .catalog-container {
    width: 100%;
    padding-bottom: var(--catalog-pb);
    flex-direction: column;
  }

  .catalog-title {
    padding: 0px 20px;
  }

  .catalog-right-side {
    margin-top: 100px;
  }

  .categories-left-side {
    position: relative;
    left: 0;
    padding-left: 0;
    flex-direction: row;
    justify-content: space-around;
    gap: 0;
  }

  .single-category-item {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0px;
  }

  .single-category-item p {
    padding: 0;
  }

  .single-cat-product {
    width: 140px;
  }

  .single-cat-product img {
    object-fit: contain;
  }

  .catalog-slide {
    position: absolute;
    top: 9rem;
    width: 100%;
  }

  .catalog-right-side {
    padding: 0 20px;
  }

  .categories-left-side {
    padding: 0;
    padding-top: 20px
  }

  .single-category-item {
    padding: 10px 20px 30px 20px;
  }

  .single-category-item img {
    height: 40px;
    width: 40px;
  }

  .left-side-desc {
    margin-top: 313px;
  }

  .modal-container {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0;
    grid-template-areas:
      'img'
      'analysis'
      'vitPerKg'
      'feedingGuide'
      'fPart'
      'sPart'
      'tPart'
      'keyBenefits'
      'ingredients'
    ;
    row-gap: 10px;

  }


  .modal-container>div {
    width: 100%
  }
}