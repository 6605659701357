@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Red Hat Display', sans-serif;
  box-sizing: border-box;
}

button {
  cursor: pointer;
  font-family: 'Red Hat Display', sans-serif;
}

a {
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8888887e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a1a1a165;
}