.container {
  max-width: 1400px;
  margin: 0 auto;
}

.App {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
p,
a {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

textarea,
input {
  font-size: 16px;
  font-family: 'Red Hat Display', sans-serif;
}

.linear-radient-bgc {
  background: var(--yellow);
  height: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}

/* neleidzia kopijuoti teksto */
/* user-select: none; */

@media(max-width: 480px) {
  .linear-radient-bgc {
    margin-bottom: 70px;
  }

  .linear-radient-bgc p {
    font-size: 10px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .linear-radient-bgc {
    margin-bottom: 70px;
  }
}