.pop-items-container {
  padding-top: var(--pop-pt);
  padding-bottom: var(--pop-pb);
  display: flex;
  justify-content: space-between;
}

.pop-items-right-side h3,
.pop-items-left-side h3 {
  font-size: var(--28px);
  font-weight: var(--regular);
  padding-bottom: 45px;
}

.pop-items-right-side {
  width: 37%;
}

.pop-items-right-side h3 {
  margin-left: 20px;
}

.pop-items-left-side {
  width: 60%;
}

.pop-items {
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  column-gap: 2.5%;
  row-gap: 32px;
}


.single-item {
  max-width: 244px;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.single-item img {
  height: 312px;
  object-fit: contain;
}


.single-item p {
  color: var(--p-grey);
  text-align: left;
  font-size: var(--16px);
  font-weight: var(--regular);
}

.single-item p:last-of-type {
  color: var(--black);
  font-weight: var(--semi-bold);
}

.new-item-container {
  background-color: var(--yellow);
  padding: 35px;
  margin: 0 20px;
}



.new-item-desc {
  background-color: #fff;
  padding: 35px;
  margin-bottom: 35px;
  height: 220px;
}

.new-item-desc h4 {
  font-size: var(--21px);
  font-weight: var(--regular);
  margin-bottom: 15px;
}

.new-item-desc p {
  font-size: var(--16px);
}

.new-item-desc p:first-of-type {
  margin-bottom: 15px;
}

.hot-new-icons {
  position: absolute;
  width: 57px;
  height: 48px;
  right: -30px;
  top: -180px;
}

.pop-items-mobile-top {
  display: none;
}

.pop-items-right-side-bottom-container {
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 477px;
  user-select: none;
}

.pop-items-right-side-bottom-container img {
  height: 440px;
  width: 100%;
  object-fit: contain;

}

.slide-icon {
  color: black;
  font-size: 3rem;
  opacity: 0.6;
}

.slide-icon:hover {
  cursor: pointer;
  transform: scale(1.08);
  transition: 0.5s ease-in-out;
  opacity: 0.8;
}

.slide-icon:active {
  transform: scale(0.95);
  transition: 0.2s;
}

.slide-icon-left {
  position: absolute;
  left: 0;
}

.slide-icon-right {
  position: absolute;
  right: 0;
}

@media(max-width: 768px) {
  .pop-items-left-side {
    display: none;
  }

  .pop-items-right-side {
    max-width: 100%;
    width: 100%;
  }

  .pop-items-mobile-top {
    display: block;
    background-color: var(--yellow);
    margin-top: -70px;
    padding-top: 70px;
  }

  .pop-items-container {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 0;
    align-items: center;
  }

  .mobile-main-container {
    text-align: center;
    position: relative;
  }

  .mobile-nose {
    position: absolute;
    left: 0;
    top: 17rem;
    max-height: 150px;
  }

  .mobile-division {
    padding-bottom: 40px;
    padding-top: 98px;
  }

  .mobile-logos {
    margin: 0 20px;
  }

  .mobile-main-container img:not(.mobile-nose) {
    width: 90%;
  }

  .pop-items-right-side {
    margin: 94px 20px 90px 20px;
  }

  .pop-items-right-side h3 {
    margin-left: 20px;
    font-size: var(--21px);
  }

  .pop-items-right-side-bottom-container {
    height: 360px;
  }

  .pop-items-right-side-bottom-container img {
    height: 300px;
  }

  .new-item-container {
    padding: 14px;
  }

  .new-item-desc {
    padding: 10px;
    margin-bottom: 14px;
  }

  .new-item-desc h4 {
    font-weight: var(--semi-bold);
    font-size: var(--16);
  }

  .new-item-desc p {
    font-weight: var(--regular);
    font-size: var(--16);
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .pop-items-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pop-items-container h3 {
    font-size: var(--21px);
    padding-bottom: 27px;
  }

  .new-item-desc h4,
  .new-item-desc p {
    font-size: var(--16px);
  }



  .single-item {
    max-width: 170px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .single-item img {
    max-height: 140px;
    max-width: 174px;
  }

  .new-item-container {
    padding: 23px;
  }

  .new-item-container img {
    height: 415px;
    width: 291px;
  }

  .new-item-desc {
    padding: 17px;
    height: max-content;
  }

  .hot-new-icons {
    top: -80px;
  }

  .pop-items-right-side {
    flex: 0.5;
  }

  .pop-items-left-side {
    flex: 0.6;
  }

}

@media screen and (min-width: 1025px) and (max-width: 1320px) {


  .pop-items-container {
    padding-left: 20px;
    padding-right: 20px;
  }


  .new-item-desc h4,
  .new-item-desc p {
    font-size: var(--16px);
  }

  .pop-items-left-side {
    flex: 0.6;
  }

  .pop-items-right-side {
    flex: 0.5;
  }

  .pop-items {
    column-gap: 20px;
    justify-content: space-around;
  }

  .single-item {
    text-align: center;
    max-width: 209px;
  }

  .single-item img {
    min-height: 120px;
  }

  .hot-new-icons {
    top: -80px;
  }

}