.hero-container {
  background-color: var(--yellow);
  height: 640px;
  user-select: none;
}

.hero-wrapper {
  position: relative;
}

.hero-title {
  position: relative;
}

.hero-headings {
  position: relative;
}

.hero-title h1 {
  position: absolute;
  font-size: 287px;
  bottom: -35.9rem;
  left: 8px;
  color: white;
}

.hero-title h2 {
  position: absolute;
  font-size: 220px;
  bottom: -44.2rem;
}

.quote {
  position: absolute;
  top: 9rem;
  left: 26rem;
  text-align: right;
  font-size: var(--28px);
}

.quote span {
  font-size: var(--21px);
}

.paws-container {
  position: relative;
  z-index: 1;
}

.paws-container img:nth-child(1) {
  position: absolute;
  bottom: 1rem;
  right: 27rem;
}


.paws-container img:nth-child(2) {
  position: absolute;
  bottom: 12rem;
  right: 14rem;
}

.paws-container img:nth-child(3) {
  position: absolute;
  bottom: 17rem;
  right: 4rem;
}

.paws-container img:nth-child(4) {
  position: absolute;
  top: -40rem;
  right: 2rem;
}

.paws-container img:nth-child(5) {
  position: absolute;
  bottom: -9rem;
  right: -7rem;
}

.fade-in-5 {
  animation: fade-in-5 500ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 1s both;
}

@keyframes fade-in-5 {
  0% {
    opacity: 0;
    filter: blur(3px);
  }

  100% {
    opacity: 1;
    filter: blur(3px);
  }
}

.fade-in-4 {
  animation: fade-in-4 500ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.2s both;
}

@keyframes fade-in-4 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.fade-in-3 {
  animation: fade-in-3 500ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.4s both;
}

@keyframes fade-in-3 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in-2 {
  animation: fade-in-2 500ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 1.8s both;
}

@keyframes fade-in-2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in-1 {
  animation: fade-in-1 500ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.3s both;
}

@keyframes fade-in-1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.paws-blur {
  animation: paws-blur 500ms cubic-bezier(0.390, 0.575, 0.565, 1.000) 2.3s both;
}

@keyframes paws-blur {
  0% {
    filter: blur(0px);
  }

  50% {
    filter: blur(2.5px);
  }

  100% {
    filter: blur(5px);
  }
}

.fade-in-left {
  animation: fade-in-left 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes fade-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 480px) {

  .hero-container {
    height: 550px;
  }

  .hero-wrapper {
    margin: 0 20px;
  }

  .hero-title h1 {
    position: absolute;
    font-size: 110px;
    top: 9rem;
    left: 3px;
  }

  .hero-title h2 {
    position: absolute;
    font-size: 84px;
    top: 14.3rem;
  }

  .quote {
    top: 21.4rem;
    left: 0rem;
    text-align: left;
    font-size: 16px;
    line-height: 2;
  }

  .quote span {
    font-size: 16px;
  }

  .paws-container img:nth-child(1) {
    width: calc(100% - 80%);
    bottom: 0rem;
    right: 11rem;
  }

  .paws-container img:nth-child(2) {
    width: calc(100% - 71%);
    bottom: 4rem;
    right: 5rem;
  }

  .paws-container img:nth-child(3) {
    width: calc(100% - 87%);
    bottom: 8rem;
    right: 2rem;
  }

  .paws-container img:nth-child(4) {
    width: calc(100% - 88%);
    top: -17rem;
    right: 1rem;
  }

  .paws-container img:nth-child(5) {
    width: calc(100% - 50%);
    bottom: -6.3rem;
    right: -4rem;
  }
}


@media screen and (min-width: 481px) and (max-width: 768px) {
  .hero-container {
    height: 550px;
  }

  .hero-wrapper {
    margin: 0 20px;
  }

  .hero-title h1 {
    position: absolute;
    font-size: 110px;
    top: 9rem;
    left: 3px;
  }

  .hero-title h2 {
    position: absolute;
    font-size: 84px;
    top: 14.3rem;
  }

  .quote {
    top: 21.4rem;
    left: 0rem;
    text-align: left;
    font-size: 16px;
    line-height: 2;
  }

  .quote span {
    font-size: 16px;
  }

  .paws-container img:nth-child(1) {
    width: calc(100% - 80%);
    bottom: 0rem;
    right: 13rem;
  }

  .paws-container img:nth-child(2) {
    width: calc(100% - 71%);
    bottom: 4rem;
    right: 5rem;
  }

  .paws-container img:nth-child(3) {
    width: calc(100% - 87%);
    bottom: 11rem;
    right: 2rem;
  }

  .paws-container img:nth-child(4) {
    width: calc(100% - 86%);
    top: -24rem;
    right: 1rem;
  }

  .paws-container img:nth-child(5) {
    width: calc(100% - 60%);
    bottom: -8.3rem;
    right: -4rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .hero-container {
    height: 550px;
  }

  .hero-wrapper {
    margin: 0 20px;
  }

  .hero-title h1 {
    position: absolute;
    font-size: 153px;
    top: 19.52rem;
    left: 4px;
  }

  .hero-title h2 {
    position: absolute;
    font-size: 117px;
    top: 26.95rem;
  }

  .quote {
    top: 13rem;
    left: 10rem;
    text-align: right;
    font-size: 16px;
    line-height: 2;
  }

  .quote span {
    font-size: 16px;
  }

  .paws-container img:nth-child(1) {
    width: calc(100% - 80%);
    bottom: 0rem;
    right: 15rem;
  }

  .paws-container img:nth-child(2) {
    width: calc(100% - 79%);
    bottom: 8.6rem;
    right: 8rem;
  }

  .paws-container img:nth-child(3) {
    width: calc(100% - 87%);
    bottom: 13rem;
    right: 2rem;
  }

  .paws-container img:nth-child(4) {
    width: calc(100% - 88%);
    top: -30rem;
    right: 1rem;
  }

  .paws-container img:nth-child(5) {
    width: calc(100% - 67%);
    bottom: -6.3rem;
    right: -5rem;
  }
}