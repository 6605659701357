nav {
  text-align: center;
  position: sticky;
  top: 0;
  background-color: hsla(0, 0%, 100%, 0.5);
  z-index: 8;
}

.blur {
  width: 100%;
  height: 100%;
  box-shadow: 0px 1.5px 3px 0px hsla(0, 0%, 67%, 0.5);
  z-index: 8;
}

.blur::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: hsla(0, 0%, 100%, 0.3);
  filter: blur(8px);
}

nav img {
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0rem;
  z-index: 8;
}

.nav-container {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: var(--21px);
  font-weight: var(--regular);
  position: relative;
  z-index: 5;
  gap: 50px;
}

.nav-container a,
.nav-container p {
  text-decoration: none;
  color: var(--black);
}

.nav-container .nav-links a:first-of-type {
  padding-right: 50px;
}

.nav-container .nav-lang a:last-of-type {
  padding-left: 10px;
}

.nav-burger {
  display: none;
}

.nav-lang {
  cursor: pointer;
  display: flex;
  gap: 10px;
}

@media(max-width: 768px) {

  .mobile-lang {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-lang div:first-of-type {
    display: flex;
    margin-left: 20px;
    gap: 20px;
  }

  .nav-burger {
    display: block;
  }

  .nav-container {
    margin-top: -80px;
  }

  nav {
    background-color: transparent;
  }

  .blur {
    background-color: transparent;
    filter: blur(0px);
    box-shadow: none;
  }

  .blur::after {
    background-color: transparent;
  }

  nav img {
    width: 45px;
    height: 45px;
    object-fit: contain;
    margin: 20px;
    z-index: 5;
  }

  .nav-burger {
    width: 45px;
    height: 45px;
    z-index: 5;
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
  }

  .nav-links,
  .nav-lang {
    display: none;
  }

  .drawer-top-side {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1201;
  }
}